export const data = {
  id: '__root__',
  start: -1450000000,
  end: 10000000,
  title: '',
  color: '#c7c0f3',
  hideBorder: true,
  exhibits: [],
  timelines: [
    {
      id: 't0',
      start: -1450000000,
      end: -1300000000,
      title: '',
      hideBorder: true,
      exhibits: [],
      timelines: [
        {
          id: 't0_1',
          start: -1450000000,
          end: -1300000000,
          title: 'Карты',
          contentText: 'Карты',
          level: 1,
          exhibits: [],
        }, {
          id: 't0_2',
          start: -1450000000,
          end: -1300000000,
          title: 'Общая характеристика',
          contentText: 'Общая хар-ка',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't0_3',
          start: -1450000000,
          end: -1300000000,
          title: 'Геология',
          contentText: 'Геология',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't0_4',
          start: -1450000000,
          end: -1300000000,
          title: 'Биология',
          contentText: 'Биология',
          level: 1,
          exhibits: [],
          timelines: [],
        },
      ],
    }, {
      id: 't1',
      start: -1300000000,
      end: -1030000000,
      title: 'T1: Средний рифей',
      color: '#c7c0f3',
      hideBorder: true,
      exhibits: [],
      timelines: [
        {
          id: 't1_1',
          start: -1300000000,
          end: -1030000000,
          title: 'T1: Средний рифей — Карты',
          color: '#c7c0f3',
          level: 1,
          exhibits: [{
            id: 'e1',
            time: -1165000000,
            title: 'Карты',
            contentItems: [{
              id: 'c1',
              title: 'Дмитровская Каграманян. ВИКА №1 Средний рифей.',
              description: '',
              mediaType: 'pdf',
              uri: 'https://raw.githubusercontent.com/slaylines/panoramica/main/data/T1P1.pdf',
            }],
          }],
        }, {
          id: 't1_2',
          start: -1300000000,
          end: -1030000000,
          title: 'T1: Средний рифей — Общая характеристика',
          color: '#c7c0f3',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't1_3',
          start: -1300000000,
          end: -1030000000,
          title: 'T1: Средний рифей — Геология',
          color: '#c7c0f3',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't1_4',
          start: -1300000000,
          end: -1030000000,
          title: 'T1: Средний рифей — Биология',
          color: '#c7c0f3',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't1_5',
          start: -1300000000,
          end: -1030000000,
          title: 'T1: Средний рифей',
          contentText: 'T1: Средний рифей',
          level: 1,
          exhibits: [],
          timelines: [],
        },
      ],
    }, {
      id: 't2',
      start: -1030000000,
      end: -600000000,
      title: 'T2: Верхний рифей',
      color: '#c7c0f3',
      hideBorder: true,
      exhibits: [],
      timelines: [
        {
          id: 't2_1',
          start: -1030000000,
          end: -600000000,
          title: 'T2: Верхний рифей — Карты',
          color: '#c7c0f3',
          level: 1,
          exhibits: [
            {
              id: 'e1',
              time: -886666666,
              title: 'Карты',
              contentItems: [{
                id: 'c1',
                title: 'Дмитровская Каграманян. ВИКА №1 Поздний рифей.',
                description: '',
                mediaType: 'pdf',
                uri: 'https://raw.githubusercontent.com/slaylines/panoramica/main/data/T2P1_1.pdf',
              }],
            }, {
              id: 'e2',
              time: -743333333,
              title: 'Карты',
              contentItems: [{
                id: 'c1',
                title: 'Расположение ВВ 750 Верх рифей.',
                description: '',
                mediaType: 'pdf',
                uri: 'https://raw.githubusercontent.com/slaylines/panoramica/main/data/T2P1_2.pdf',
              }],
            }
          ],
          timelines: [],
        }, {
          id: 't2_2',
          start: -1030000000,
          end: -600000000,
          title: 'T2: Верхний рифей — Общая характеристика',
          color: '#c7c0f3',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't2_3',
          start: -1030000000,
          end: -600000000,
          title: 'T2: Верхний рифей — Геология',
          color: '#c7c0f3',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't2_4',
          start: -1030000000,
          end: -600000000,
          title: 'T2: Верхний рифей — Биология',
          color: '#c7c0f3',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't2_5',
          start: -1030000000,
          end: -600000000,
          title: 'T2: Верхний рифей',
          contentText: 'T2: Верхний рифей',
          level: 1,
          exhibits: [],
          timelines: [],
        }
      ],
    }, {
      id: 't3',
      start: -600000000,
      end: -541000000,
      title: 'T3: Венд',
      color: '#c7c0f3',
      hideBorder: true,
      exhibits: [],
      timelines: [
        {
          id: 't3_1',
          start: -600000000,
          end: -541000000,
          title: 'T3: Венд — Карты',
          color: '#c7c0f3',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't3_2',
          start: -600000000,
          end: -541000000,
          title: 'T3: Венд — Общая характеристика',
          color: '#c7c0f3',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't3_3',
          start: -600000000,
          end: -541000000,
          title: 'T3: Венд — Геология',
          color: '#c7c0f3',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't3_4',
          start: -600000000,
          end: -541000000,
          title: 'T3: Венд — Биология',
          color: '#c7c0f3',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't3_5',
          start: -600000000,
          end: -541000000,
          title: 'T3: Венд',
          contentText: 'T3: Венд',
          level: 1,
          exhibits: [],
          timelines: [],
        }
      ],
    }, {
      id: 't4',
      start: -541000000,
      end: -485000000,
      title: 'T4: Кембрий',
      color: '#c7c0f3',
      hideBorder: true,
      exhibits: [],
      timelines: [
        {
          id: 't4_1',
          start: -541000000,
          end: -485000000,
          title: 'T4: Кембрий — Карты',
          color: '#c7c0f3',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't4_2',
          start: -541000000,
          end: -485000000,
          title: 'T4: Кембрий — Общая характеристика',
          color: '#c7c0f3',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't4_3',
          start: -541000000,
          end: -485000000,
          title: 'T4: Кембрий — Геология',
          color: '#c7c0f3',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't4_4',
          start: -541000000,
          end: -485000000,
          title: 'T4: Кембрий — Биология',
          color: '#c7c0f3',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't4_5',
          start: -541000000,
          end: -485000000,
          title: 'T4: Кембрий',
          contentText: 'T4: Кембрий',
          level: 1,
          exhibits: [],
          timelines: [],
        }
      ],
    }, {
      id: 't5',
      start: -485000000,
      end: -443800000,
      title: 'T5: Ордовик',
      color: '#c7c0f3',
      hideBorder: true,
      exhibits: [],
      timelines: [
        {
          id: 't5_1',
          start: -485000000,
          end: -443800000,
          title: 'T5: Ордовик — Карты',
          color: '#c7c0f3',
          level: 1,
          exhibits: [
            {
              id: 'e1',
              time: -456533333,
              title: 'Карты',
              contentItems: [{
                id: 'c1',
                title: 'Дмитровская Каграманян. ВИКА №1 Ордовик.',
                description: '',
                mediaType: 'pdf',
                uri: 'https://raw.githubusercontent.com/slaylines/panoramica/main/data/T5P1_1.pdf',
              }]
            }, {
              id: 'e2',
              time: -472266666,
              title: 'Карты',
              contentItems: [{
                id: 'c1',
                title: 'Расположение ВВ 470 Ордовик.',
                description: '',
                mediaType: 'pdf',
                uri: 'https://raw.githubusercontent.com/slaylines/panoramica/main/data/T5P1_2.pdf',
              }]
            }
          ],
          timelines: [],
        }, {
          id: 't5_2',
          start: -485000000,
          end: -443800000,
          title: 'T5: Ордовик — Общая характеристика',
          color: '#c7c0f3',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't5_3',
          start: -485000000,
          end: -443800000,
          title: 'T5: Ордовик — Геология',
          color: '#c7c0f3',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't5_4',
          start: -485000000,
          end: -443800000,
          title: 'T5: Ордовик — Биология',
          color: '#c7c0f3',
          level: 1,
          exhibits: [
            {
              id: 'e1',
              time: -456533333,
              title: 'Биология',
              contentItems: [{
                id: 'c1',
                title: 'Картинка моря ордовика.',
                description: '',
                mediaType: 'image',
                uri: 'https://raw.githubusercontent.com/slaylines/panoramica/main/data/T5P4.jpg'
              }]
            }, {
              id: 'e2',
              time: -472266666,
              title: 'Карты',
              contentItems: [{
                id: 'c2',
                title: 'Ссылки по Органике Ордовика.',
                description: '',
                mediaType: 'pdf',
                uri: 'https://raw.githubusercontent.com/slaylines/panoramica/main/data/T5P4_2.pdf'
              }]
            }
          ],
          timelines: [],
        }, {
          id: 't5_5',
          start: -485000000,
          end: -443800000,
          title: 'T5: Ордовик',
          contentText: 'T5: Ордовик',
          level: 1,
          exhibits: [],
          timelines: [],
        }
      ],
    }, {
      id: 't6',
      start: -443800000,
      end: -419000000,
      title: 'T6: Силур',
      color: '#c7c0f3',
      hideBorder: true,
      exhibits: [],
      timelines: [
        {
          id: 't6_1',
          start: -443800000,
          end: -419000000,
          title: 'T6: Силур — Карты',
          color: '#c7c0f3',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't6_2',
          start: -443800000,
          end: -419000000,
          title: 'T6: Силур — Общая характеристика',
          color: '#c7c0f3',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't6_3',
          start: -443800000,
          end: -419000000,
          title: 'T6: Силур — Геология',
          color: '#c7c0f3',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't6_4',
          start: -443800000,
          end: -419000000,
          title: 'T6: Силур — Биология',
          color: '#c7c0f3',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't6_5',
          start: -443800000,
          end: -419000000,
          title: 'T6: Силур',
          contentText: 'T6: Силур',
          level: 1,
          exhibits: [],
          timelines:[],
        }
      ],
    }, {
      id: 't7',
      start: -419000000,
      end: -358900000,
      title: 'T7: Девон',
      color: '#c7c0f3',
      hideBorder: true,
      exhibits: [],
      timelines: [
        {
          id: 't7_1',
          start: -419000000,
          end: -358900000,
          title: 'T7: Девон — Карты',
          color: '#c7c0f3',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't7_2',
          start: -419000000,
          end: -358900000,
          title: 'T7: Девон — Общая характеристика',
          color: '#c7c0f3',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't7_3',
          start: -419000000,
          end: -358900000,
          title: 'T7: Девон — Геология',
          color: '#c7c0f3',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't7_4',
          start: -419000000,
          end: -358900000,
          title: 'T7: Девон — Биология',
          color: '#c7c0f3',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't7_5',
          start: -419000000,
          end: -358900000,
          title: 'T7: Девон',
          contentText: 'T7: Девон',
          level: 1,
          exhibits: [],
          timelines:[],
        }
      ],
    }, {
      id: 't8',
      start: -358900000,
      end: -298900000,
      title: 'T8: Каменугольный',
      color: '#c7c0f3',
      hideBorder: true,
      exhibits: [],
      timelines: [
        {
          id: 't8_1',
          start: -358900000,
          end: -298900000,
          title: 'T8: Каменугольный — Карты',
          color: '#c7c0f3',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't8_2',
          start: -358900000,
          end: -298900000,
          title: 'T8: Каменугольный — Общая характеристика',
          color: '#c7c0f3',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't8_3',
          start: -358900000,
          end: -298900000,
          title: 'T8: Каменугольный — Геология',
          color: '#c7c0f3',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't8_4',
          start: -358900000,
          end: -298900000,
          title: 'T8: Каменугольный — Биология',
          color: '#c7c0f3',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't8_5',
          start: -358900000,
          end: -298900000,
          title: 'T8: Каменугольный',
          contentText: 'T8: Каменугольный',
          level: 1,
          exhibits: [],
          timelines:[],
        }
      ],
    }, {
      id: 't9',
      start: -298900000,
      end: -251900000,
      title: 'T9: Пермь',
      color: '#c7c0f3',
      hideBorder: true,
      exhibits: [],
      timelines: [
        {
          id: 't9_1',
          start: -298900000,
          end: -251900000,
          title: 'T9: Пермь — Карты',
          color: '#c7c0f3',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't9_2',
          start: -298900000,
          end: -251900000,
          title: 'T9: Пермь — Общая характеристика',
          color: '#c7c0f3',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't9_3',
          start: -298900000,
          end: -251900000,
          title: 'T9: Пермь — Геология',
          color: '#c7c0f3',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't9_4',
          start: -298900000,
          end: -251900000,
          title: 'T9: Пермь — Биология',
          color: '#c7c0f3',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't9_5',
          start: -298900000,
          end: -251900000,
          title: 'T9: Пермь',
          contentText: 'T9: Пермь',
          level: 1,
          exhibits: [],
          timelines:[],
        }
      ],
    }, {
      id: 't10',
      start: -251900000,
      end: -201000000,
      title: 'T10: Триас',
      color: '#c7c0f3',
      hideBorder: true,
      exhibits: [],
      timelines: [
        {
          id: 't10_1',
          start: -251900000,
          end: -201000000,
          title: 'T10: Триас — Карты',
          color: '#c7c0f3',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't10_2',
          start: -251900000,
          end: -201000000,
          title: 'T10: Триас — Общая характеристика',
          color: '#c7c0f3',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't10_3',
          start: -251900000,
          end: -201000000,
          title: 'T10: Триас — Геология',
          color: '#c7c0f3',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't10_4',
          start: -251900000,
          end: -201000000,
          title: 'T10: Триас — Биология',
          color: '#c7c0f3',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't10_5',
          start: -251900000,
          end: -201000000,
          title: 'T10: Триас',
          contentText: 'T10: Триас',
          level: 1,
          exhibits: [],
          timelines:[],
        }
      ],
    }, {
      id: 't11',
      start: -201000000,
      end: -145000000,
      title: 'T11: Юра',
      color: '#c7c0f3',
      hideBorder: true,
      exhibits: [],
      timelines: [
        {
          id: 't11_1',
          start: -201000000,
          end: -145000000,
          title: 'T11: Юра — Карты',
          color: '#c7c0f3',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't11_2',
          start: -201000000,
          end: -145000000,
          title: 'T11: Юра — Общая характеристика',
          color: '#c7c0f3',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't11_3',
          start: -201000000,
          end: -145000000,
          title: 'T11: Юра — Геология',
          color: '#c7c0f3',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't11_4',
          start: -201000000,
          end: -145000000,
          title: 'T11: Юра — Биология',
          color: '#c7c0f3',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't11_5',
          start: -201000000,
          end: -145000000,
          title: 'T11: Юра',
          contentText: 'T11: Юра',
          level: 1,
          exhibits: [],
          timelines:[],
        }
      ],
    }, {
      id: 't12',
      start: -145000000,
      end: -66000000,
      title: 'T12: Мел',
      color: '#c7c0f3',
      hideBorder: true,
      exhibits: [],
      timelines: [
        {
          id: 't12_1',
          start: -145000000,
          end: -66000000,
          title: 'T12: Мел — Карты',
          color: '#c7c0f3',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't12_2',
          start: -145000000,
          end: -66000000,
          title: 'T12: Мел — Общая характеристика',
          color: '#c7c0f3',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't12_3',
          start: -145000000,
          end: -66000000,
          title: 'T12: Мел — Геология',
          color: '#c7c0f3',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't12_4',
          start: -145000000,
          end: -66000000,
          title: 'T12: Мел — Биология',
          color: '#c7c0f3',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't12_5',
          start: -145000000,
          end: -66000000,
          title: 'T12: Мел',
          contentText: 'T12: Мел',
          level: 1,
          exhibits: [],
          timelines:[],
        }
      ],
    }, {
      id: 't13',
      start: -66000000,
      end: -23000000,
      title: 'T13: Палеоген',
      color: '#c7c0f3',
      hideBorder:true,
      exhibits: [],
      timelines: [
        {
          id: 't13_1',
          start: -66000000,
          end: -23000000,
          title: 'T13: Палеоген — Карты',
          color: '#c7c0f3',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't13_2',
          start: -66000000,
          end: -23000000,
          title: 'T13: Палеоген — Общая характеристика',
          color: '#c7c0f3',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't13_3',
          start: -66000000,
          end: -23000000,
          title: 'T13: Палеоген — Геология',
          color: '#c7c0f3',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't13_4',
          start: -66000000,
          end: -23000000,
          title: 'T13: Палеоген — Биология',
          color: '#c7c0f3',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't13_5',
          start: -66000000,
          end: -23000000,
          title: 'T13: Палеоген',
          contentText: 'T13: Палеоген',
          level: 1,
          exhibits: [],
          timelines:[],
        }
      ],
    }, {
      id: 't14',
      start: -23000000,
      end: -2580000,
      title: 'T14: Неоген',
      color: '#c7c0f3',
      hideBorder: true,
      exhibits: [],
      timelines: [
        {
          id: 't14_1',
          start: -23000000,
          end: -2580000,
          title: 'T14: Неоген — Карты',
          color: '#c7c0f3',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't14_2',
          start: -23000000,
          end: -2580000,
          title: 'T14: Неоген — Общая характеристика',
          color: '#c7c0f3',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't14_3',
          start: -23000000,
          end: -2580000,
          title: 'T14: Неоген — Геология',
          color: '#c7c0f3',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't14_4',
          start: -23000000,
          end: -2580000,
          title: 'T14: Неоген — Биология',
          color: '#c7c0f3',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't14_5',
          start: -23000000,
          end: -2580000,
          title: 'T14: Неоген',
          contentText: 'T14: Неоген',
          level: 1,
          exhibits: [],
          timelines:[],
        }
      ],
    }, {
      id: 't15',
      start: -2580000,
      end: 10000000,
      title: 'T15: Антропоген',
      color: '#c0c7a9',
      hideBorder: true,
      exhibits: [],
      timelines: [
        {
          id: 't15_1',
          start: -2580000,
          end: 10000000,
          title: 'T15: Антропоген — Карты',
          color: '#c0c7a9',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't15_2',
          start: -2580000,
          end: 10000000,
          title: 'T15: Антропоген — Общая характеристика',
          color: '#c0c7a9',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't15_3',
          start: -2580000,
          end: 10000000,
          title: 'T15: Антропоген — Геология',
          color: '#c0c7a9',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't15_4',
          start: -2580000,
          end: 10000000,
          title: 'T15: Антропоген — Биология',
          color: '#c0c7a9',
          level: 1,
          exhibits: [],
          timelines: [],
        }, {
          id: 't15_5',
          start: -2580000,
          end: 10000000,
          title: 'T15: Антропоген',
          contentText: 'T15: Антропоген',
          level: 1,
          exhibits: [],
          timelines:[],
        }
      ],
    },
  ],
};
