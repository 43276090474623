import { OperatorFunction, TimestampProvider, Timestamp } from '../types';
import { dateTimestampProvider } from '../scheduler/dateTimestampProvider';
import { map } from './map';

/**
 * Attaches a timestamp to each item emitted by an observable indicating when it was emitted
 *
 * The `timestamp` operator maps the *source* observable stream to an object of type
 * `{value: T, timestamp: R}`. The properties are generically typed. The `value` property contains the value
 * and type of the *source* observable. The `timestamp` is generated by the schedulers `now` function. By
 * default it uses the *async* scheduler which simply returns `Date.now()` (milliseconds since 1970/01/01
 * 00:00:00:000) and therefore is of type `number`.
 *
 * ![](timestamp.png)
 *
 * ## Example
 *
 * In this example there is a timestamp attached to the documents click event.
 *
 * ```ts
 * import { fromEvent } from 'rxjs';
 * import { timestamp } from 'rxjs/operators';
 *
 * const clickWithTimestamp = fromEvent(document, 'click').pipe(
 *   timestamp()
 * );
 *
 * // Emits data of type {value: MouseEvent, timestamp: number}
 * clickWithTimestamp.subscribe(data => {
 *   console.log(data);
 * });
 * ```
 *
 * @param timestampProvider An object with a `now()` method used to get the current timestamp.
 * @return A function that returns an Observable that attaches a timestamp to
 * each item emitted by the source Observable indicating when it was emitted.
 */
export function timestamp<T>(timestampProvider: TimestampProvider = dateTimestampProvider): OperatorFunction<T, Timestamp<T>> {
  return map((value: T) => ({ value, timestamp: timestampProvider.now()}));
}
